export default {
  v: '4.8.0',
  meta: {
    g: 'LottieFiles AE 3.3.6', a: '', k: '', d: '', tc: '',
  },
  fr: 60,
  ip: 0,
  op: 420,
  w: 800,
  h: 800,
  nm: 'src',
  ddd: 0,
  assets: [],
  layers: [{
    ddd: 0,
    ind: 2,
    ty: 4,
    nm: 'Shape Layer 39',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.4, y: 1 },
            o: { x: 0.8, y: 0 },
            t: 60,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-32.75, -19.625], [0, -0.125]], c: false,
            }],
          }, {
            t: 80,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-32.75, -19.625], [-0.25, -37.75]], c: false,
            }],
          }],
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 66,
    op: 76,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 3,
    ty: 4,
    nm: 'Shape Layer 38',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.4, y: 1 },
            o: { x: 0.8, y: 0 },
            t: 60,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[0.25, 0], [32.5, -19.75]], c: false,
            }],
          }, {
            t: 80,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[0, -38], [32.5, -19.75]], c: false,
            }],
          }],
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 66,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 4,
    ty: 4,
    nm: 'Shape Layer 37',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.4, y: 1 },
            o: { x: 0.8, y: 0 },
            t: 60,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-0.375, 0.125], [-0.375, 0.125]], c: false,
            }],
          }, {
            t: 80,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-0.375, -38], [-0.375, 0.125]], c: false,
            }],
          }],
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 66,
    op: 72,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 5,
    ty: 4,
    nm: 'Shape Layer 36',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    ef: [{
      ty: 5,
      nm: 'Layer Control',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 1,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 42, ix: 1 },
      }],
    }, {
      ty: 5,
      nm: 'Layer Control 2',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 2,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 53, ix: 1 },
      }],
    }],
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-5.541, 41.253], [0, 75.875]], c: false,
          },
          ix: 2,
          x: "var $bm_rt;\nvar l1, p1, l2, p2;\nl1 = effect('Layer Control')('Layer');\np1 = $bm_sub(l1.toComp(l1.transform.anchorPoint), $bm_transform.position);\nl2 = effect('Layer Control 2')('Layer');\np2 = $bm_sub(l2.toComp(l2.transform.anchorPoint), $bm_transform.position);\n$bm_rt = createPath([\n    p1,\n    p2\n], [], [], true);",
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 50,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 6,
    ty: 4,
    nm: 'Shape Layer 41',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    ef: [{
      ty: 5,
      nm: 'Layer Control',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 1,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 36, ix: 1 },
      }],
    }, {
      ty: 5,
      nm: 'Layer Control 2',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 2,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 41, ix: 1 },
      }],
    }],
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-5.541, 41.253], [0, 75.875]], c: false,
          },
          ix: 2,
          x: "var $bm_rt;\nvar l1, p1, l2, p2;\nl1 = effect('Layer Control')('Layer');\np1 = $bm_sub(l1.toComp(l1.transform.anchorPoint), $bm_transform.position);\nl2 = effect('Layer Control 2')('Layer');\np2 = $bm_sub(l2.toComp(l2.transform.anchorPoint), $bm_transform.position);\n$bm_rt = createPath([\n    p1,\n    p2\n], [], [], true);",
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 48,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 7,
    ty: 4,
    nm: 'Shape Layer 40',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    ef: [{
      ty: 5,
      nm: 'Layer Control',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 1,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 41, ix: 1 },
      }],
    }, {
      ty: 5,
      nm: 'Layer Control 2',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 2,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 50, ix: 1 },
      }],
    }],
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-5.541, 41.253], [0, 75.875]], c: false,
          },
          ix: 2,
          x: "var $bm_rt;\nvar l1, p1, l2, p2;\nl1 = effect('Layer Control')('Layer');\np1 = $bm_sub(l1.toComp(l1.transform.anchorPoint), $bm_transform.position);\nl2 = effect('Layer Control 2')('Layer');\np2 = $bm_sub(l2.toComp(l2.transform.anchorPoint), $bm_transform.position);\n$bm_rt = createPath([\n    p1,\n    p2\n], [], [], true);",
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 48,
    op: 66,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 8,
    ty: 4,
    nm: 'Shape Layer 35',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    ef: [{
      ty: 5,
      nm: 'Layer Control',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 1,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 41, ix: 1 },
      }],
    }, {
      ty: 5,
      nm: 'Layer Control 2',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 2,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 42, ix: 1 },
      }],
    }],
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-5.541, 41.253], [0, 75.875]], c: false,
          },
          ix: 2,
          x: "var $bm_rt;\nvar l1, p1, l2, p2;\nl1 = effect('Layer Control')('Layer');\np1 = $bm_sub(l1.toComp(l1.transform.anchorPoint), $bm_transform.position);\nl2 = effect('Layer Control 2')('Layer');\np2 = $bm_sub(l2.toComp(l2.transform.anchorPoint), $bm_transform.position);\n$bm_rt = createPath([\n    p1,\n    p2\n], [], [], true);",
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 48,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 9,
    ty: 4,
    nm: 'Shape Layer 34',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[98.625, 18.875], [65.75, 38]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 70,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 10,
    ty: 4,
    nm: 'Shape Layer 33',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 42,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[65.75, 0.25], [71.25, 3.875]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 44,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[65.75, 0.25], [87.875, 12.875]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 46,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[65.75, 0.25], [95.125, 17.062]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 48,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[65.75, 0.25], [97.75, 18.438]], c: false,
            }],
          }, {
            t: 50,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[65.75, 0.25], [98.5, 19.062]], c: false,
            }],
          }],
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 42,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 11,
    ty: 4,
    nm: 'Shape Layer 32',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 38,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[33, -19.625], [42.5, -13.625]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 40,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[33, -19.625], [52.375, -7.875]], c: false,
            }],
          }, {
            t: 42,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[33, -19.625], [65.688, -0.125]], c: false,
            }],
          }],
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 12,
    ty: 4,
    nm: 'Shape Layer 31',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[0, 0], [32.625, -19.625]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 13,
    ty: 4,
    nm: 'Shape Layer 30',
    sr: 1,
    ks: {
      o: { a: 1, k: [{ t: 38, s: [100], h: 1 }, { t: 40, s: [0], h: 1 }, { t: 42, s: [100], h: 1 }], ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    ef: [{
      ty: 5,
      nm: 'Layer Control',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 1,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 42, ix: 1 },
      }],
    }, {
      ty: 5,
      nm: 'Layer Control 2',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 2,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 50, ix: 1 },
      }],
    }],
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-5.541, 41.253], [0, 75.875]], c: false,
          },
          ix: 2,
          x: "var $bm_rt;\nvar l1, p1, l2, p2;\nl1 = effect('Layer Control')('Layer');\np1 = $bm_sub(l1.toComp(l1.transform.anchorPoint), $bm_transform.position);\nl2 = effect('Layer Control 2')('Layer');\np2 = $bm_sub(l2.toComp(l2.transform.anchorPoint), $bm_transform.position);\n$bm_rt = createPath([\n    p1,\n    p2\n], [], [], true);",
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 48,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 14,
    ty: 4,
    nm: 'Shape Layer 29',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    ef: [{
      ty: 5,
      nm: 'Layer Control',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 1,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 36, ix: 1 },
      }],
    }, {
      ty: 5,
      nm: 'Layer Control 2',
      np: 3,
      mn: 'ADBE Layer Control',
      ix: 2,
      en: 1,
      ef: [{
        ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 42, ix: 1 },
      }],
    }],
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-5.541, 41.253], [0, 75.875]], c: false,
          },
          ix: 2,
          x: "var $bm_rt;\nvar l1, p1, l2, p2;\nl1 = effect('Layer Control')('Layer');\np1 = $bm_sub(l1.toComp(l1.transform.anchorPoint), $bm_transform.position);\nl2 = effect('Layer Control 2')('Layer');\np2 = $bm_sub(l2.toComp(l2.transform.anchorPoint), $bm_transform.position);\n$bm_rt = createPath([\n    p1,\n    p2\n], [], [], true);",
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 42,
    op: 48,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 15,
    ty: 4,
    nm: 'Shape Layer 28',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-33.25, -19.625], [-33, 18.75]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 66,
    op: 68,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 16,
    ty: 4,
    nm: 'Shape Layer 27',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-33, -19.5], [-0.125, 0.25]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 17,
    ty: 4,
    nm: 'Shape Layer 26',
    sr: 1,
    ks: {
      o: { a: 1, k: [{ t: 38, s: [100], h: 1 }, { t: 40, s: [0], h: 1 }, { t: 42, s: [100], h: 1 }], ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-33.375, -57.75], [-66, -38]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 18,
    ty: 4,
    nm: 'Shape Layer 25',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-33, -57.125], [-33.125, -19.25]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 19,
    ty: 4,
    nm: 'Shape Layer 24',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-33, -19.125], [-66, -0.25]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 20,
    ty: 4,
    nm: 'Shape Layer 23',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 38,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-71.375, -3.25], [-65.625, 0.125]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 40,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-84, -10.5], [-65.625, 0.125]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 42,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-91.375, -14.5], [-65.625, 0.125]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 44,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-95, -16.875], [-65.625, 0.125]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 46,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-97.188, -18.062], [-65.625, 0.125]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 48,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-98.062, -18.688], [-65.625, 0.125]], c: false,
            }],
          }, {
            t: 50,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-98.5, -18.875], [-65.625, 0.125]], c: false,
            }],
          }],
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 21,
    ty: 4,
    nm: 'Shape Layer 22',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-65.875, -38], [-65.875, -0.125]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 22,
    ty: 4,
    nm: 'Shape Layer 21',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 38,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-65.75, -37.5], [-71.75, -2.875]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 40,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-65.75, -37.5], [-83.875, -10.75]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 42,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-65.75, -37.5], [-91, -14.625]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 44,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-65.75, -37.5], [-95.25, -17]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 46,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-65.75, -37.5], [-96.875, -18.188]], c: false,
            }],
          }, {
            i: { x: 0.833, y: 0.833 },
            o: { x: 0.167, y: 0.167 },
            t: 48,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-65.75, -37.5], [-98.125, -18.875]], c: false,
            }],
          }, {
            t: 50,
            s: [{
              i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[-65.75, -37.5], [-98.188, -19.125]], c: false,
            }],
          }],
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 74,
    st: 38,
    bm: 0,
  }, {
    ddd: 0,
    ind: 23,
    ty: 4,
    nm: 'line 6 Shape',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 30, ix: 10 }, p: { a: 0, k: [334.5, 322.875, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[0.795, 0.225], [-19.25, 0.188]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 220, s: [0],
        }, { t: 240, s: [100] }],
        ix: 1,
      },
      e: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 200, s: [0],
        }, { t: 220, s: [100] }],
        ix: 2,
      },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Trim Paths 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 200,
    op: 242,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 24,
    ty: 4,
    nm: 'line 5 Shape',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [622.875, 342.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[49.67, 0.201], [-29.75, 0.125]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 184, s: [0],
        }, { t: 194, s: [100] }],
        ix: 1,
      },
      e: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 154, s: [0],
        }, { t: 184, s: [100] }],
        ix: 2,
      },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Trim Paths 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 154,
    op: 196,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 25,
    ty: 4,
    nm: 'line 4 Shape',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 90, ix: 10 }, p: { a: 0, k: [334.875, 594.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[99.045, 0.263], [-59.75, 0.188]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 138, s: [0],
        }, { t: 148, s: [100] }],
        ix: 1,
      },
      e: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 108, s: [0],
        }, { t: 138, s: [100] }],
        ix: 2,
      },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Trim Paths 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 108,
    op: 150,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 26,
    ty: 4,
    nm: 'line 2 Shape',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [422.875, 260.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [],
    ip: 170,
    op: 212,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 27,
    ty: 4,
    nm: 'line 3 Shape',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [302, 417.875, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [],
    ip: 120,
    op: 162,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 28,
    ty: 4,
    nm: 'line Shape',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -31, ix: 10 }, p: { a: 0, k: [399, 436.875, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[0.795, 0.225], [-19.25, 0.188]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 1, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 80, s: [0],
        }, { t: 100, s: [100] }],
        ix: 1,
      },
      e: {
        a: 1,
        k: [{
          i: { x: [0.4], y: [1] }, o: { x: [0.8], y: [0] }, t: 60, s: [0],
        }, { t: 80, s: [100] }],
        ix: 2,
      },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Trim Paths 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 60,
    op: 102,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 29,
    ty: 4,
    nm: 'Shape Layer 1',
    sr: 1,
    ks: {
      o: { a: 1, k: [{ t: 246, s: [100], h: 1 }, { t: 248, s: [0], h: 1 }, { t: 250, s: [100], h: 1 }, { t: 252, s: [100], h: 1 }], ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ty: 'sr',
        sy: 2,
        d: 1,
        pt: { a: 0, k: 6, ix: 3 },
        p: { a: 0, k: [0, 0], ix: 4 },
        r: { a: 0, k: 0, ix: 5 },
        or: {
          a: 1,
          k: [{
            i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 250, s: [110],
          }, { t: 252, s: [95] }],
          ix: 7,
        },
        os: { a: 0, k: 0, ix: 9 },
        ix: 1,
        nm: 'Polystar Path 1',
        mn: 'ADBE Vector Shape - Star',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.5, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Polystar 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm', s: { a: 0, k: 0, ix: 1 }, e: { a: 0, k: 100, ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Trim Paths 1', mn: 'ADBE Vector Filter - Trim', hd: false,
    }],
    ip: 246,
    op: 260,
    st: 246,
    bm: 0,
  }, {
    ddd: 0,
    ind: 30,
    ty: 4,
    nm: 'Shape Layer 2',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 266, s: [400, 400, 0], to: [0, -6.333, 0], ti: [0, 6.333, 0],
        }, { t: 268, s: [400, 362, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ty: 'sr', sy: 2, d: 1, pt: { a: 0, k: 6, ix: 3 }, p: { a: 0, k: [0, 0], ix: 4 }, r: { a: 0, k: 0, ix: 5 }, or: { a: 0, k: 19, ix: 7 }, os: { a: 0, k: 0, ix: 9 }, ix: 1, nm: 'Polystar Path 1', mn: 'ADBE Vector Shape - Star', hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.5, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Polystar 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm', s: { a: 0, k: 0, ix: 1 }, e: { a: 0, k: 100, ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Trim Paths 1', mn: 'ADBE Vector Filter - Trim', hd: false,
    }],
    ip: 260,
    op: 274,
    st: 260,
    bm: 0,
  }, {
    ddd: 0,
    ind: 31,
    ty: 4,
    nm: 'Shape Layer 10',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: {
        a: 1,
        k: [{
          i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 148, s: [60],
        }, { t: 162, s: [0] }],
        ix: 10,
      },
      p: { a: 0, k: [466, 437.5, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ty: 'sr',
        sy: 2,
        d: 1,
        pt: { a: 0, k: 6, ix: 3 },
        p: { a: 0, k: [0, 0], ix: 4 },
        r: { a: 0, k: 0, ix: 5 },
        or: {
          a: 1,
          k: [{
            i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 148, s: [19],
          }, { t: 162, s: [0] }],
          ix: 7,
        },
        os: { a: 0, k: 0, ix: 9 },
        ix: 1,
        nm: 'Polystar Path 1',
        mn: 'ADBE Vector Shape - Star',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.5, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Polystar 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm', s: { a: 0, k: 0, ix: 1 }, e: { a: 0, k: 100, ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Trim Paths 1', mn: 'ADBE Vector Filter - Trim', hd: false,
    }],
    ip: 144,
    op: 172,
    st: 130,
    bm: 0,
  }, {
    ddd: 0,
    ind: 32,
    ty: 4,
    nm: 'Shape Layer 11',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: {
        a: 1,
        k: [{
          i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 194, s: [60],
        }, { t: 208, s: [0] }],
        ix: 10,
      },
      p: { a: 0, k: [268, 399.5, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ty: 'sr',
        sy: 2,
        d: 1,
        pt: { a: 0, k: 6, ix: 3 },
        p: { a: 0, k: [0, 0], ix: 4 },
        r: { a: 0, k: 0, ix: 5 },
        or: {
          a: 1,
          k: [{
            i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 194, s: [19],
          }, { t: 208, s: [0] }],
          ix: 7,
        },
        os: { a: 0, k: 0, ix: 9 },
        ix: 1,
        nm: 'Polystar Path 1',
        mn: 'ADBE Vector Shape - Star',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.5, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Polystar 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm', s: { a: 0, k: 0, ix: 1 }, e: { a: 0, k: 100, ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Trim Paths 1', mn: 'ADBE Vector Filter - Trim', hd: false,
    }],
    ip: 190,
    op: 218,
    st: 176,
    bm: 0,
  }, {
    ddd: 0,
    ind: 33,
    ty: 4,
    nm: 'Shape Layer 3',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 70, s: [367, 342, 0], to: [0, -6.25, 0], ti: [0, 6.25, 0],
        }, { t: 90, s: [367, 304.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ty: 'rc', d: 1, s: { a: 0, k: [50, 0], ix: 2 }, p: { a: 0, k: [-25, 0], ix: 3 }, r: { a: 0, k: 0, ix: 4 }, nm: 'Rectangle Path 1', mn: 'ADBE Vector Shape - Rect', hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.5, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 30, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Rectangle 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 70,
    op: 88,
    st: 10,
    bm: 0,
  }, {
    ddd: 0,
    ind: 34,
    ty: 4,
    nm: 'Shape Layer 4',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 120, s: [499.5, 457, 0], to: [0, 6.25, 0], ti: [0, -6.25, 0],
        }, { t: 140, s: [499.5, 494.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ty: 'rc', d: 1, s: { a: 0, k: [50, 0], ix: 2 }, p: { a: 0, k: [-25, 0], ix: 3 }, r: { a: 0, k: 0, ix: 4 }, nm: 'Rectangle Path 1', mn: 'ADBE Vector Shape - Rect', hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.5, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 180, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Rectangle 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 120,
    op: 134,
    st: 58,
    bm: 0,
  }, {
    ddd: 0,
    ind: 35,
    ty: 4,
    nm: 'Shape Layer 20',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.667, y: 1 },
            o: { x: 0.167, y: 0.167 },
            t: 212,
            s: [{
              i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], v: [[-0.016, -38.25], [-49.25, -9.902], [-49.377, 28.5], [-0.084, 0.089], [49.583, 28.62], [49.709, -9.402]], c: true,
            }],
          }, {
            t: 226,
            s: [{
              i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], v: [[-0.016, -38.25], [-49.25, -9.902], [-49.459, 9.5], [-0.167, -18.911], [49.5, 9.62], [49.709, -9.402]], c: true,
            }],
          }],
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.5, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Shape 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 212,
    op: 248,
    st: 68,
    bm: 0,
  }, {
    ddd: 0,
    ind: 36,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 127,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [0, 0, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 270, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 282, s: [41, 41, 100],
        }, { t: 290, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 37,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 128,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 140, s: [16.454, -9.5, 0], to: [0, 3.167, 0], ti: [0, -3.167, 0],
        }, { t: 160, s: [16.454, 9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 276, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 288, s: [41, 41, 100],
        }, { t: 296, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 140,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 38,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 128,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 10, s: [0, 0, 0], to: [2.742, -1.583, 0], ti: [-2.742, 1.583, 0],
        }, { t: 30, s: [16.454, -9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 272, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 284, s: [61, 61, 100],
        }, { t: 292, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 10,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 39,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 128,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 60, s: [0, 0, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 80, s: [0, -19, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 266, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 278, s: [61, 61, 100],
        }, { t: 286, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 60,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 40,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 128,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 0, s: [0, 0, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 20, s: [-16.454, -9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 272, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 284, s: [61, 61, 100],
        }, { t: 292, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 41,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 128,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [-16.454, 9.5, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 50, s: [37.5, 37.5, 100],
        }, {
          i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] }, o: { x: [0.309, 0.309, 0.309], y: [0, 0, 0] }, t: 58, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 276, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 288, s: [41, 41, 100],
        }, { t: 296, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 50,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 42,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 128,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 40, s: [-16.455, 28.5, 0], to: [2.742, -1.583, 0], ti: [-2.742, 1.583, 0],
        }, { t: 60, s: [0, 19, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 30, s: [37.5, 37.5, 100],
        }, { t: 38, s: [12.5, 12.5, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 30,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 43,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [32.909, 19, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 90, s: [37.5, 37.5, 100],
        }, {
          i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] }, o: { x: [0.157, 0.157, 0.157], y: [0, 0, 0] }, t: 98, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 282, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 294, s: [41, 41, 100],
        }, { t: 302, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 90,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 44,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [32.909, 0, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 278, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 290, s: [61, 61, 100],
        }, { t: 298, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 42,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 45,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 70, s: [32.909, 0, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 90, s: [32.909, -19, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 278, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 290, s: [61, 61, 100],
        }, { t: 298, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 70,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 46,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 100, s: [16.454, -9.5, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 120, s: [16.455, -28.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 272, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 284, s: [61, 61, 100],
        }, { t: 292, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 100,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 47,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 220, s: [16.455, -47.5, 0], to: [-2.742, 1.583, 0], ti: [2.742, -1.583, 0],
        }, { t: 240, s: [0, -38, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 266, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 278, s: [61, 61, 100],
        }, { t: 286, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 220,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 48,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 20, s: [-16.454, -9.5, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 40, s: [-16.454, -28.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 272, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 284, s: [61, 61, 100],
        }, { t: 292, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 20,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 49,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 70, s: [-16.454, -9.5, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 90, s: [-32.909, -19, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 278, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 290, s: [61, 61, 100],
        }, { t: 298, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 70,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 50,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [-32.909, 0, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 278, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 290, s: [61, 61, 100],
        }, { t: 298, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 38,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 51,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [-32.909, 19, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 282, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 294, s: [41, 41, 100],
        }, { t: 302, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 108,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 52,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 60, s: [0, 19, 0], to: [-2.742, 1.583, 0], ti: [2.742, -1.583, 0],
        }, { t: 80, s: [-16.455, 28.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 60,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 53,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 50, s: [-16.455, 28.5, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 70, s: [0, 38, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 50,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 54,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 132,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 120, s: [16.454, 9.5, 0], to: [0, 3.167, 0], ti: [0, -3.167, 0],
        }, { t: 140, s: [16.454, 28.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 100, s: [37.5, 37.5, 100],
        }, { t: 108, s: [12.5, 12.5, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 100,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 55,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 70, s: [32.909, 19, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 90, s: [49.363, 28.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 288, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 300, s: [41, 41, 100],
        }, { t: 308, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 70,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 56,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 30, s: [16.454, -9.5, 0], to: [5.485, 3.167, 0], ti: [-5.485, -3.167, 0],
        }, { t: 50, s: [49.363, 9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 10, s: [37.5, 37.5, 100],
        }, {
          i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] }, o: { x: [0.317, 0.317, 0.317], y: [0, 0, 0] }, t: 18, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 284, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 296, s: [61, 61, 100],
        }, { t: 304, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 10,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 57,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 80, s: [32.909, 0, 0], to: [2.742, -1.583, 0], ti: [-2.742, 1.583, 0],
        }, { t: 100, s: [49.363, -9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 284, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 296, s: [61, 61, 100],
        }, { t: 304, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 80,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 58,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [49.363, -28.5, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 288, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 300, s: [41, 41, 100],
        }, { t: 308, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 138,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 59,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 100, s: [32.909, -19, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 120, s: [32.909, -38, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 282, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 294, s: [41, 41, 100],
        }, { t: 302, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 100,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 60,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 200, s: [32.909, -38, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 220, s: [16.455, -47.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 276, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 288, s: [41, 41, 100],
        }, { t: 296, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 200,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 61,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 240, s: [0, -38, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 260, s: [0, -57, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 270, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 282, s: [41, 41, 100],
        }, { t: 290, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 240,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 62,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 70, s: [-16.454, -28.5, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 90, s: [-16.454, -47.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 276, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 288, s: [41, 41, 100],
        }, { t: 296, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 70,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 63,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 80, s: [-32.909, -19, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 100, s: [-32.909, -38, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 40, s: [37.5, 37.5, 100],
        }, {
          i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] }, o: { x: [0.31, 0.31, 0.31], y: [0, 0, 0] }, t: 48, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 282, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 294, s: [41, 41, 100],
        }, { t: 302, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 40,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 64,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [-49.363, -28.5, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 170, s: [37.5, 37.5, 100],
        }, {
          i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] }, o: { x: [0.3, 0.3, 0.3], y: [0, 0, 0] }, t: 178, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 288, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 300, s: [41, 41, 100],
        }, { t: 308, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 170,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 65,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 20, s: [-16.454, 9.5, 0], to: [-5.485, -3.167, 0], ti: [5.485, 3.167, 0],
        }, { t: 50, s: [-49.363, -9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 284, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 296, s: [61, 61, 100],
        }, { t: 304, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 20,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 66,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 100, s: [-49.363, -9.5, 0], to: [0, 3.167, 0], ti: [0, -3.167, 0],
        }, { t: 120, s: [-49.363, 9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 284, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 296, s: [61, 61, 100],
        }, { t: 304, s: [50, 50, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 100,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 67,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 130, s: [-32.909, 38, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 150, s: [-49.363, 28.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 288, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 300, s: [41, 41, 100],
        }, { t: 308, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 130,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 68,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 90, s: [-32.909, 0, 0], to: [0, 6.333, 0], ti: [0, -6.333, 0],
        }, { t: 120, s: [-32.909, 38, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 90,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 69,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-16.455, 47.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 148,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 70,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 170, s: [-16.455, 47.5, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 190, s: [0, 57, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 170,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 71,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 90, s: [49.363, 28.5, 0], to: [-5.485, 3.167, 0], ti: [5.485, -3.167, 0],
        }, { t: 110, s: [16.454, 47.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 90,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 72,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 129,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [32.908, 38, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 102,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 73,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 170, s: [65.818, 0, 0], to: [0, 6.333, 0], ti: [0, -6.333, 0],
        }, { t: 200, s: [65.818, 38, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 170,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 74,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [65.818, 19, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 290, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 302, s: [41, 41, 100],
        }, { t: 310, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 188,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 75,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 100, s: [49.363, -9.5, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 120, s: [65.818, 0, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 290, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 302, s: [41, 41, 100],
        }, { t: 310, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 100,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 76,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 240, s: [49.363, -28.5, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 260, s: [65.818, -19, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 294, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 306, s: [41, 41, 100],
        }, { t: 314, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 240,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 77,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 120, s: [32.909, -19, 0], to: [5.485, -3.167, 0], ti: [-5.485, 3.167, 0],
        }, { t: 150, s: [65.818, -38, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 120,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 78,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [49.364, -47.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 218,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 79,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 150, s: [32.909, -38, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 170, s: [32.909, -57, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 150,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 80,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [16.455, -66.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 188,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 81,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 170, s: [32.909, -57, 0], to: [-5.485, -3.167, 0], ti: [5.485, 3.167, 0],
        }, { t: 200, s: [0, -76, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 170,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 82,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 260, s: [0, -57, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 280, s: [-16.454, -66.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 260,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 83,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 250, s: [-16.454, -47.5, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 270, s: [-32.909, -57, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 250,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 84,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 200, s: [-32.909, -38, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 220, s: [-49.363, -47.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 200,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 85,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-65.817, -38, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 198,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 86,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 160, s: [-49.363, -9.5, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 180, s: [-65.818, -19.001, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 294, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 306, s: [41, 41, 100],
        }, { t: 314, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 160,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 87,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 120, s: [-49.363, 9.5, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 140, s: [-65.818, -0.001, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 290, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 302, s: [41, 41, 100],
        }, { t: 310, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 120,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 88,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [-65.818, 18.999, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.677, 0.677, 0.742], y: [0, 0, 0] }, t: 290, s: [12.5, 12.5, 100],
        }, {
          i: { x: [0.331, 0.331, 0.335], y: [1, 1, 1] }, o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] }, t: 302, s: [41, 41, 100],
        }, { t: 310, s: [30, 30, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 248,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 89,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 200, s: [-49.363, 28.5, 0], to: [-2.742, 1.583, 0], ti: [2.742, -1.583, 0],
        }, { t: 220, s: [-65.818, 37.999, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 200,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 90,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-49.364, 47.5, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 288,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 91,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-32.91, 57, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 208,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 92,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 130, s: [-16.455, 28.5, 0], to: [0, 6.333, 0], ti: [0, -6.333, 0],
        }, { t: 160, s: [-16.455, 66.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 130,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 93,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 260, s: [16.454, 66.5, 0], to: [-2.742, 1.583, 0], ti: [2.742, -1.583, 0],
        }, { t: 280, s: [-0.001, 76, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 260,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 94,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 200, s: [49.363, 47.501, 0], to: [-5.485, 3.167, 0], ti: [5.485, -3.167, 0],
        }, { t: 230, s: [16.454, 66.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 200,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 95,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [32.908, 57, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 218,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 96,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 130,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 120, s: [49.363, 28.5, 0], to: [0, 3.167, 0], ti: [0, -3.167, 0],
        }, { t: 140, s: [49.363, 47.501, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 120,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 97,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 210, s: [65.818, 38, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 230, s: [82.272, 47.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 210,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 98,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 220, s: [65.818, 19, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 240, s: [82.272, 28.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 220,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 99,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 280, s: [82.272, -9.5, 0], to: [0, 3.167, 0], ti: [0, -3.167, 0],
        }, { t: 300, s: [82.272, 9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 280,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 100,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 240, s: [82.272, -28.5, 0], to: [0, 3.167, 0], ti: [0, -3.167, 0],
        }, { t: 260, s: [82.272, -9.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 240,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 101,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [82.272, -28.5, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] }, o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] }, t: 180, s: [37.5, 37.5, 100],
        }, { t: 188, s: [12.5, 12.5, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 180,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 102,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 230, s: [65.818, -38, 0], to: [2.742, -1.583, 0], ti: [-2.742, 1.583, 0],
        }, { t: 250, s: [82.272, -47.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 230,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 103,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 200, s: [32.909, -38, 0], to: [5.485, -3.167, 0], ti: [-5.485, 3.167, 0],
        }, { t: 230, s: [65.818, -57, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 200,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 104,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 230, s: [49.364, -47.5, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 250, s: [49.364, -66.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 230,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 105,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 240, s: [32.909, -57, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 260, s: [32.909, -76, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 240,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 106,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 220, s: [0, -76, 0], to: [2.742, -1.583, 0], ti: [-2.742, 1.583, 0],
        }, { t: 240, s: [16.455, -85.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 220,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 107,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 200, s: [0, -76, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 220, s: [0, -95, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 200,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 108,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 280, s: [-16.454, -66.5, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 300, s: [-16.454, -85.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 280,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 109,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 230, s: [-32.909, -57, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 250, s: [-32.908, -76, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 230,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 110,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 270, s: [-49.363, -47.5, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 290, s: [-49.363, -66.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 270,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 111,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 180, s: [-65.818, -19.001, 0], to: [0, -6.333, 0], ti: [0, 6.333, 0],
        }, { t: 210, s: [-65.817, -57, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 180,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 112,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 250, s: [-65.817, -57, 0], to: [-2.742, 1.583, 0], ti: [2.742, -1.583, 0],
        }, { t: 270, s: [-82.272, -47.5, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 250,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 113,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 280, s: [-82.272, -9.501, 0], to: [0, -3.167, 0], ti: [0, 3.167, 0],
        }, { t: 300, s: [-82.272, -28.501, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 280,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 114,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 210, s: [-65.818, -0.001, 0], to: [-2.742, -1.583, 0], ti: [2.742, 1.583, 0],
        }, { t: 230, s: [-82.272, -9.501, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 210,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 115,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 230, s: [-49.363, 28.5, 0], to: [-5.485, -3.167, 0], ti: [5.485, 3.167, 0],
        }, { t: 260, s: [-82.272, 9.499, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 230,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 116,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 260, s: [-65.818, 18.999, 0], to: [-2.742, 1.583, 0], ti: [2.742, -1.583, 0],
        }, { t: 280, s: [-82.272, 28.499, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 260,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 117,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 250, s: [-65.818, 37.999, 0], to: [-2.742, 1.583, 0], ti: [2.742, -1.583, 0],
        }, { t: 270, s: [-82.272, 47.499, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 250,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 118,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 270, s: [-32.909, 38, 0], to: [-5.485, 3.167, 0], ti: [5.485, -3.167, 0],
        }, { t: 300, s: [-65.819, 56.999, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 270,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 119,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 190, s: [-16.455, 47.5, 0], to: [-5.485, 3.167, 0], ti: [5.485, -3.167, 0],
        }, { t: 220, s: [-49.365, 66.499, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 190,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 120,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 220, s: [-49.365, 66.499, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 240, s: [-32.91, 75.999, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 220,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 121,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 240, s: [-32.91, 75.999, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 260, s: [-16.456, 85.499, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 240,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 122,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 280, s: [-0.001, 76, 0], to: [0, 3.167, 0], ti: [0, -3.167, 0],
        }, { t: 300, s: [-0.001, 94.999, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 280,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 123,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 230, s: [16.454, 66.5, 0], to: [0, 4, 0], ti: [0, -3.167, 0],
        }, { t: 250, s: [16.453, 85.501, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 230,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 124,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 260, s: [16.453, 85.501, 0], to: [2.742, -1.583, 0], ti: [-2.742, 1.583, 0],
        }, { t: 280, s: [32.908, 76.001, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 260,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 125,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 160, s: [49.363, 47.501, 0], to: [0, 3.167, 0], ti: [0, -3.167, 0],
        }, { t: 180, s: [49.362, 66.501, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 160,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 126,
    ty: 4,
    nm: 'Sphere 2 Shape',
    parent: 131,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: {
        a: 1,
        k: [{
          i: { x: 0.4, y: 1 }, o: { x: 0.8, y: 0 }, t: 240, s: [49.363, 47.501, 0], to: [2.742, 1.583, 0], ti: [-2.742, -1.583, 0],
        }, { t: 260, s: [65.816, 57.001, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: { a: 0, k: [12.5, 12.5, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        d: 1, ty: 'el', s: { a: 0, k: [20, 20], ix: 2 }, p: { a: 0, k: [0, 0], ix: 3 }, nm: 'Ellipse Path 1', mn: 'ADBE Vector Shape - Ellipse', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Ellipse 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 240,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 127,
    ty: 3,
    nm: 'Null 2',
    sr: 1,
    ks: {
      o: { a: 0, k: 0, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [400, 400, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 },
    },
    ao: 0,
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 128,
    ty: 3,
    nm: 'Null 1',
    parent: 127,
    sr: 1,
    ks: {
      o: { a: 0, k: 0, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [0, 0, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.593, 0.593, 0.667], y: [1, 1, 1] }, o: { x: [0.697, 0.697, 0.333], y: [0, 0, 0] }, t: 280, s: [100, 100, 100],
        }, {
          i: { x: [0.066, 0.066, 0.667], y: [1, 1, 1] }, o: { x: [0.226, 0.226, 0.333], y: [0, 0, 0] }, t: 298, s: [120, 120, 100],
        }, { t: 320, s: [100, 100, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 129,
    ty: 3,
    nm: 'Null 1',
    parent: 127,
    sr: 1,
    ks: {
      o: { a: 0, k: 0, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [0, 0, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.593, 0.593, 0.667], y: [1, 1, 1] }, o: { x: [0.697, 0.697, 0.333], y: [0, 0, 0] }, t: 292, s: [100, 100, 100],
        }, {
          i: { x: [0.066, 0.066, 0.667], y: [1, 1, 1] }, o: { x: [0.226, 0.226, 0.333], y: [0, 0, 0] }, t: 310, s: [118, 118, 100],
        }, { t: 334, s: [100, 100, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 130,
    ty: 3,
    nm: 'Null 1',
    parent: 127,
    sr: 1,
    ks: {
      o: { a: 0, k: 0, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [0, 0, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.594, 0.594, 0.667], y: [1, 1, 1] }, o: { x: [0.697, 0.697, 0.333], y: [0, 0, 0] }, t: 298, s: [100, 100, 100],
        }, {
          i: { x: [0.066, 0.066, 0.667], y: [1, 1, 1] }, o: { x: [0.226, 0.226, 0.333], y: [0, 0, 0] }, t: 316, s: [115, 115, 100],
        }, { t: 344, s: [100, 100, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 131,
    ty: 3,
    nm: 'Null 1',
    parent: 127,
    sr: 1,
    ks: {
      o: { a: 0, k: 0, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [0, 0, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.593, 0.593, 0.667], y: [1, 1, 1] }, o: { x: [0.697, 0.697, 0.333], y: [0, 0, 0] }, t: 304, s: [100, 100, 100],
        }, {
          i: { x: [0.066, 0.066, 0.667], y: [1, 1, 1] }, o: { x: [0.226, 0.226, 0.333], y: [0, 0, 0] }, t: 322, s: [110, 110, 100],
        }, { t: 356, s: [100, 100, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 132,
    ty: 3,
    nm: 'Null 1',
    parent: 127,
    sr: 1,
    ks: {
      o: { a: 0, k: 0, ix: 11 },
      r: { a: 0, k: 0, ix: 10 },
      p: { a: 0, k: [0, 0, 0], ix: 2 },
      a: { a: 0, k: [0, 0, 0], ix: 1 },
      s: {
        a: 1,
        k: [{
          i: { x: [0.594, 0.594, 0.667], y: [1, 1, 1] }, o: { x: [0.697, 0.697, 0.333], y: [0, 0, 0] }, t: 286, s: [100, 100, 100],
        }, {
          i: { x: [0.066, 0.066, 0.667], y: [1, 1, 1] }, o: { x: [0.226, 0.226, 0.333], y: [0, 0, 0] }, t: 304, s: [119, 119, 100],
        }, { t: 326, s: [100, 100, 100] }],
        ix: 6,
      },
    },
    ao: 0,
    ip: 0,
    op: 420,
    st: 0,
    bm: 0,
  }],
  markers: [{ tm: 42, cm: '', dr: 0 }, { tm: 46, cm: '', dr: 0 }, { tm: 72, cm: '', dr: 0 }],
};
